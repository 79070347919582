
import axios from 'axios'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { SIGN_OBJ } from '@/config/constEnum'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  name: 'Sign',
  setup() {
    const name = ref('')
    const userName = ref('')
    const region = ref(0)
    const date = ref('')
    const radio = ref()
    const checkStatus = ref(0)

    const total = ref(0)
    const pageSize = 10
    const page = ref(1)
    const rejectId = ref(0)
    const rejectReason = ref('')

    const tableData = ref([])
    const dialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const rejectDialogVisible = ref(false)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const formatTime = (val: string) => {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    }

    const getList = () => {
      const reqData = {
        status: 'all',
        size: pageSize,
        page: page.value
      }
      axios.get('/-/admin_sms/sign/list', { params: reqData }).then(res => {
        const data = res.data.msg
        total.value = data.total
        tableData.value = data.signs
      })
    }

    const onChangePage = (val: any) => {
      page.value = val
      getList()
    }

    const onSubmit = () => {
      const reqData = {
        user_name: userName.value,
        name: name.value
      }

      axios
        .post('/-/admin_sms/sign/new', reqData)
        .then(() => {
          ElMessage.success('创建成功')
          dialogVisible.value = false
          userName.value = ''
          name.value = ''
          getList()
        })
        .catch(e => {
          console.warn(e)
        })
    }

    const audit = (val: number, isAccess: boolean) => {
      if (isAccess) {
        axios
          .post('/-/admin_sms/sign/approve', { id: val })
          .then(() => {
            ElMessage.success('通过成功')
            getList()
          })
          .catch(e => {
            console.warn(e)
          })
      } else {
        rejectId.value = val
        rejectDialogVisible.value = true
      }
    }

    const reject = () => {
      axios
        .post('/-/admin_sms/sign/reject', { id: rejectId.value, reject_reason: rejectReason.value })
        .then(() => {
          ElMessage.success('拒绝成功')
          rejectReason.value = ''
          rejectDialogVisible.value = false
          getList()
        })
        .catch(e => {
          console.warn(e)
        })
    }

    onMounted(() => {
      getList()
    })
    return {
      name,
      date,
      page,
      radio,
      total,
      audit,
      region,
      reject,
      rejectId,
      userName,
      onSubmit,
      pageSize,
      tableData,
      formatTime,
      checkStatus,
      handleClose,
      onChangePage,
      rejectReason,
      dialogVisible,
      seeDialogVisible,
      signObj: SIGN_OBJ,
      rejectDialogVisible
    }
  }
})
